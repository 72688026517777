html,
body,
#root {
  min-height: 100vh;
  margin: 0;
  background-color: hsl(208, 21%, 94%);
}

#root {
  display: flex;
}

.MuiTableCell-head {
  font-weight: 700 !important;
}

/* .MuiCardHeader-root,
.MuiCardContent-root {
  padding: 8px !important;
} */

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  header,
  .MuiDrawer-paper {
    /* Status bar height on iOS 10 */
    padding-top: 20px;
    /* Status bar height on iOS 11.0 */
    padding-top: constant(safe-area-inset-top);
    /* Status bar height on iOS 11+ */
    padding-top: env(safe-area-inset-top);
  }

  main {
    margin-top: 40px;
  }
}

